/*
   Loaders
**/

.commonLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

:local(.loader) {
  @extend .commonLoader;
  height: 100vh !important;
  max-height:100vh !important;
}

:local(.inlineLoader) {
  display: flex;
  justify-content: center;
  width: 100%;
}
