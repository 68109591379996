@import '../../sass/colors';

:local(.launcher) {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  width: 20px;
  height: 20px;

  &:hover {
    padding: 3px;
  }

  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

