@import '~antd/dist/antd.css';
@import '../sass/colors';

@font-face {
  font-family: Montserrat-Regular;
  src: url('./fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('./fonts/Montserrat-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Forwood-Regular;
  src: url('./fonts/FORWOOD_Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

$montserrat-regular: Montserrat-Regular, Arial, Helvetica, sans-serif;
$montserrat-semibold: Montserrat-SemiBold, Arial, Helvetica, sans-serif;

html {
  body {
    font-family: $montserrat-regular;
  }
}

/*
    Menu
**/

.ant-menu {
  font-family: $montserrat-semibold;
}

:lang(sr) {
  .ant-menu {
    font-weight: bold;
    font-family: sans-serif;
  }
}